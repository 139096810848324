import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import { AUTH_API_PROVIDER } from './domain/auth/infrastructure/providers/auth-api.provider';
import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { loaderInterceptor } from './domain/shared/infrastructure/interceptors/loader.interceptor';
import { headersInterceptor } from './domain/shared/infrastructure/interceptors/headers.interceptor';
import { USER_API_PROVIDER } from './domain/admin/users/infrastructure/providers/user-api.provider';
import { MODULE_API_PROVIDER } from './domain/admin/modules/infrastructure/providers/modules.provider';
import { ROLE_API_PROVIDER } from './domain/admin/roles/infrastructure/providers/role.provider';
import { COMPANY_API_PROVIDER } from './domain/admin/companies/infrastructure/providers/company.provider';
import { COMPANIES_BRANCHES_API_PROVIDER } from './domain/admin/companiesBranches/infrastructure/providers/companies-branches.provider';
import { authInterceptor } from './domain/shared/infrastructure/interceptors/auth.interceptor';
import { EXCHANGE_API_PROVIDER } from './domain/security-monitoring/exchanges/infrastructure/providers/exchange.provider';
import { CUSTOMER_API_PROVIDER } from './domain/admin/customers/infrasctructure/providers/customer-api.provider';
import { OPERATOR_API_PROVIDER } from './domain/security-monitoring/operators/infrastructure/providers/operator.provider';
import { CARRIERS_LINES_API_PROVIDER } from './domain/security-monitoring/carriers-lines/infrastructure/providers/carriers-lines.provider';
import { PLATES_TYPE_API_PROVIDER } from './domain/admin/plates_type/infrastructure/providers/plates-type.provider';
import { TRUCK_API_PROVIDER } from './domain/security-monitoring/trucks/infrastructure/providers/truck.provider';
import { TRUCK_BRAND_PROVIDER } from './domain/security-monitoring/truck-brand/infrastructure/providers/truck-brand.provider';
import { TRUCK_INSPECTION_PROVIDER } from './domain/security-monitoring/truck-inspection/infrastructure/providers/truck-inspection.provider';
import { TIRE_STATE_PROVIDER } from './domain/security-monitoring/tire-state/infrastructure/providers/tire-state.provider';
import { TIRE_BRAND_PROVIDER } from './domain/security-monitoring/tire-brand/infrastructure/providers/tire-brand.provider';
import { TIRE_PROVIDER } from './domain/security-monitoring/tire/infrastructure/provider/tire.provider';
import { UPLOAD_FILE_PROVIDER } from './domain/upload-files/infrastructure/providers/upload-file.provider';
import { PRINTED_CARD_PROVIDER } from './domain/security-monitoring/printed-cards/infrastructure/providers/printed-card.provider';
import { STAMP_INSIDE_THE_ENVELOPE_PROVIDER } from './domain/security-monitoring/stamp-inside-the-envelope/infrastructure/providers/stamp-inside-the-envelope.provider';
import { TRAILER_PROVIDER } from './domain/security-monitoring/trailer/infrastructure/providers/trailer.provider';
import { TRAILER_OWNER_PROVIDER } from './domain/security-monitoring/trailer-owner/infrastructure/providers/trailer-owner.provider';
import { TRAILER_TYPE_PROVIDER } from './domain/security-monitoring/trailer-type/infrastructure/providers/trailer-type.provider';
import { TRAILER_INSPECTION_PROVIDER } from './domain/security-monitoring/trailer-inspection/infrastructure/providers/trailer-inspection.provider';
import { ENVELOPE_ASSIGNED_PROVIDER } from './domain/security-monitoring/envelopes_assign_to_operator/infrastructure/providers/envelope-assigned.provider';
import { TRAILER_INSPECTION_DAMAGE_PROVIDER } from './domain/security-monitoring/trailer_inspection_damage/infrastructure/providers/trailer-inspection-damage.provider';
import { YARD_BOX_PROVIDER } from './domain/security-monitoring/yard-boxes/infrastructure/providers/yard-box.provider';
import { LOG_PROVIDER } from './domain/security-monitoring/logs/infrastructure/providers/log.provider';
import { DAMAGE_PROVIDER } from './domain/security-monitoring/damages/infrastructure/providers/damage.provider';
import { DAMAGE_PHOTO_PROVIDER } from './domain/security-monitoring/damages_photos/infrastructure/providers/damage-photo.provider';
import { ASSIGN_CONTROL_TRAILER_TIRES_PROVIDER } from './domain/security-monitoring/AssingControlTrailerTiresTWT/infrastructure/provider/assign-control-trailer-tires.provider';
import { TRUCK_EXCHANGE_PROVIDER } from './domain/maintenance/truck-exchange/infrastructure/providers/truck-exchange.provider';
import { TRUCK_FRONT_INSPECTION_PROVIDER } from './domain/maintenance/truck-front-inspection/infrastructure/providers/truck-front-inspection.provider';
import { TRUCK_INTERIOR_INSPECTION_PROVIDER } from './domain/maintenance/truck-interior-inspection/infrastructure/providers/truck-interior-inspection.provider';
import { TRUCK_OTHER_INSPECTION_PROVIDER } from './domain/maintenance/truck-other-inspection/infrastructure/providers/truck-other-inspection.provider';
import { TRUCK_REAR_INSPECTION_PROVIDER } from './domain/maintenance/truck-rear-inspection/infrastructure/providers/truck-rear-inspection.provider';
import { TRUCK_SIDES_INSPECTION_PROVIDERS } from './domain/maintenance/truck-sides-inspection/infrastructure/providers/truck-side-inspection.provider';
import { EXTERN_TIRES_PROVIDER } from './domain/security-monitoring/extern_tires/infrastructure/provider/extern-tires.provider';
import { TIRE_DAMAGE_PROVIDER } from './domain/security-monitoring/tire-damage/infrastructure/provider/tire-damage.provider';
import { ASSIGN_CONTROL_TRUCK_TIRES_PROVIDER } from './domain/maintenance/assign-control-truck-tires-twt/infrastructure/provider/assign-control-truck-tires.provider';
import { DASHBOARD_API_PROVIDER } from './domain/security-monitoring/dashboard/infrastructure/providers/dashboard.provider';
import { provideServiceWorker } from '@angular/service-worker';
import { TRAILER_CONTROL_PROVIDER } from './domain/trailers-control/trailer-control/infrastructure/providers/trailer-control.provider';
import { TRUCK_EXCHANGE_DAMAGE_PROVIDER } from './domain/maintenance/truck-exchange-damage/infrastructure/providers/truck-exchange-damage.provider';
import { SEAL_TYPE_PROVIDER } from './domain/trailers-control/seals-type/infrastructure/providers/seal-type.provider';
import { UBICATION_PROVIDER } from './domain/trailers-control/ubications/infrastructure/providers/ubication.provider';
import { ODOMETER_PROVIDER } from './domain/maintenance/odometer/infrastructure/provider/odometer.provider';
import { YARD_PROVIDER } from './domain/trailers-control/yards/infrastructure/providers/yard.provider';
import { TRAILER_CONTROL_REPORT_PROVIDER } from './domain/trailers-control/trailer-control-reports/infrastructure/providers/trailer-control-report.provider';
import { provideNativeDateAdapter } from '@angular/material/core';
import { DAMAGE_UNFIT_PROVIDER } from './domain/security-monitoring/damages-unfit/infrastructure/providers/damage-unfit.provider';
import { accessTokenInterceptor } from './domain/shared/infrastructure/interceptors/access-token.interceptor';
import { EMPLOYEE_PROVIDER } from './domain/admin/employees/infrastructure/employee-api.provider';
import { TRUCK_FOLDER_INSPECTION_PROVIDER } from './domain/maintenance/truck-folder-inspection/infrastructure/providers/truck-folder-inspection-api.provider';
import { VACANTS_PROVIDER } from './domain/human-resources/vacants/infrastructure/providers/vacant.provider';
import { DEPARTMENT_PROVIDER } from './domain/human-resources/departments/infrastructure/providers/department.provider';
import { VACANT_APPLICATION_PROVIDER } from './domain/human-resources/vacant-applications/infrastructure/vacant-application.provider';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        loaderInterceptor,
        headersInterceptor,
        accessTokenInterceptor,
      ])
    ),
    provideAnimationsAsync(),
    provideNativeDateAdapter(),
    ASSIGN_CONTROL_TRAILER_TIRES_PROVIDER,
    ASSIGN_CONTROL_TRUCK_TIRES_PROVIDER,
    AUTH_API_PROVIDER,
    CARRIERS_LINES_API_PROVIDER,
    COMPANIES_BRANCHES_API_PROVIDER,
    COMPANY_API_PROVIDER,
    CUSTOMER_API_PROVIDER,
    DAMAGE_PHOTO_PROVIDER,
    DAMAGE_PROVIDER,
    DAMAGE_UNFIT_PROVIDER,
    DASHBOARD_API_PROVIDER,
    DEPARTMENT_PROVIDER,
    EMPLOYEE_PROVIDER,
    ENVELOPE_ASSIGNED_PROVIDER,
    EXCHANGE_API_PROVIDER,
    EXTERN_TIRES_PROVIDER,
    LOG_PROVIDER,
    MODULE_API_PROVIDER,
    ODOMETER_PROVIDER,
    OPERATOR_API_PROVIDER,
    PLATES_TYPE_API_PROVIDER,
    PRINTED_CARD_PROVIDER,
    ROLE_API_PROVIDER,
    SEAL_TYPE_PROVIDER,
    STAMP_INSIDE_THE_ENVELOPE_PROVIDER,
    TIRE_BRAND_PROVIDER,
    TIRE_DAMAGE_PROVIDER,
    TIRE_PROVIDER,
    TIRE_STATE_PROVIDER,
    TRAILER_CONTROL_PROVIDER,
    TRAILER_CONTROL_REPORT_PROVIDER,
    TRAILER_INSPECTION_DAMAGE_PROVIDER,
    TRAILER_INSPECTION_PROVIDER,
    TRAILER_OWNER_PROVIDER,
    TRAILER_PROVIDER,
    TRAILER_TYPE_PROVIDER,
    TRUCK_API_PROVIDER,
    TRUCK_BRAND_PROVIDER,
    TRUCK_EXCHANGE_DAMAGE_PROVIDER,
    TRUCK_EXCHANGE_PROVIDER,
    TRUCK_FOLDER_INSPECTION_PROVIDER,
    TRUCK_FRONT_INSPECTION_PROVIDER,
    TRUCK_INSPECTION_PROVIDER,
    TRUCK_INTERIOR_INSPECTION_PROVIDER,
    TRUCK_OTHER_INSPECTION_PROVIDER,
    TRUCK_REAR_INSPECTION_PROVIDER,
    TRUCK_SIDES_INSPECTION_PROVIDERS,
    UBICATION_PROVIDER,
    UPLOAD_FILE_PROVIDER,
    USER_API_PROVIDER,
    VACANT_APPLICATION_PROVIDER,
    VACANTS_PROVIDER,
    YARD_BOX_PROVIDER,
    YARD_PROVIDER,

    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }), provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            registrationStrategy: 'registerWhenStable:30000'
          }),
  ],
};
