import { Routes } from '@angular/router';
import { userHasSystemsRoleGuard } from './domain/shared/infrastructure/guards/user-has-systems-role.guard';
import { userHasSecurityRoleGuard } from './domain/shared/infrastructure/guards/user-has-security-role.guard';
import { isLoguedGuard } from './domain/shared/infrastructure/guards/is-logued.guard';
import { userHasMaintenanceGuard } from './domain/shared/infrastructure/guards/user-has-maintenance.guard';
import { userHasTrailersControlGuard } from './domain/shared/infrastructure/guards/user-has-trailers-control.guard';
import { validateTokenGuard } from './domain/shared/infrastructure/guards/validate-token.guard';
// import { isAuthenticatedGuard } from './domain/shared/infrastructure/guards/is-authenticated.guard';
// import { validateTokenGuard } from './domain/shared/infrastructure/guards/validate-token.guard';
// import { userHasPublicRoleGuard } from './domain/shared/infrastructure/guards/user-has-public-role.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./ui/auth/screens/login-page/login-page.component').then(
        (c) => c.LoginPageComponent
      ),
    canActivate: [isLoguedGuard],
  },
  {
    path: 'main',
    loadComponent: () =>
      import('./ui/main-menu/screens/menu/menu.component').then(
        (c) => c.MenuComponent
      ),
    canActivate: [validateTokenGuard],
  },
  {
    path: 'security-monitoring',
    loadChildren: () =>
      import('./ui/security-monitoring/security-monitoring.routes').then(
        (m) => m.ROUTES_SECURITY
      ),
    canActivate: [validateTokenGuard, userHasSecurityRoleGuard],
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./ui/maintenance/maintenance.routes').then(
        (m) => m.ROUTES_MAINTENANCE
      ),
    canActivate: [validateTokenGuard, userHasMaintenanceGuard],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./ui/admin/admin.routes').then((m) => m.ROUTES_ADMIN),
    canActivate: [validateTokenGuard, userHasSystemsRoleGuard],
  },
  {
    path: 'trailers-control',
    loadChildren: () =>
      import('./ui/trailers-control/trailers-control.routes').then(
        (m) => m.ROUTES_TRAILER_CONTROL
      ),
    canActivate: [validateTokenGuard, userHasTrailersControlGuard],
  },
  {
    path: 'exchange/:uuid',
    loadComponent: () =>
      import(
        './ui/public/screens/exchange-summary-screen/exchange-summary-screen.component'
      ).then((c) => c.ExchangeSummaryScreenComponent),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./ui/auth/screens/not-found-page/not-found-page.component').then(
        (c) => c.NotFoundPageComponent
      ),
  },
];

// {
//   path: 'public',
//   loadChildren: () =>
//     import('./ui/public/public.routes').then((m) => m.ROUTES_PUBLIC),
//   canActivate: [
//     validateTokenGuard,
//     isAuthenticatedGuard,
//     userHasPublicRoleGuard,
//   ],
// },
