import { Injectable, inject } from '@angular/core';
import { IYardBoxesApiService } from './yard-boxes-api.interface';
import { Observable, map, take } from 'rxjs';
import { YardBoxesWithTrailerApiModel } from './models/yard-boxes-with-trailer-api.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';

import { ResponseApi } from '../../../shared/infrastructure/models/response-api.interface';
import { YardBoxWithTrailer } from '../domain/BoxWithTrailer.model';

@Injectable({
  providedIn: 'root',
})
export class YardBoxesApiService implements IYardBoxesApiService {
  private readonly _http = inject(HttpClient);
  private BASE_URL = environment.baseUrl;

  private _setMapBoxWithTrailers(
    yardBoxWithTrailer: YardBoxesWithTrailerApiModel
  ): YardBoxWithTrailer {
    return {
      id: yardBoxWithTrailer.id,
      updatedAt: yardBoxWithTrailer.updatedAt,
      spaceNumber: yardBoxWithTrailer.spaceNumber,
      spaceType: yardBoxWithTrailer.spaceType,
      trailerId: yardBoxWithTrailer.trailerID,
      exchangeId: yardBoxWithTrailer.exchangeID,
      name: yardBoxWithTrailer.name,
      plates: yardBoxWithTrailer.plates,
      plateState: yardBoxWithTrailer.plateState,
      isBlockedUp: yardBoxWithTrailer.isBlockedUp,
      isUnfit: yardBoxWithTrailer.isUnfit,
      isEmpty: yardBoxWithTrailer.isEmpty,
      emptyRacks: yardBoxWithTrailer.emptyRacks,
      ownerName: yardBoxWithTrailer.ownerName,
      trailerTypeName: yardBoxWithTrailer.trailerTypeName,
    };
  }

  private _setMapBoxWithTrailersList(
    yardBoxesWithTrailers: YardBoxesWithTrailerApiModel[]
  ): YardBoxWithTrailer[] {
    return yardBoxesWithTrailers.map((yardBoxWithTrailer) =>
      this._setMapBoxWithTrailers(yardBoxWithTrailer)
    );
  }

  getBoxesWithTrailers(): Observable<YardBoxWithTrailer[]> {
    const url = `${this.BASE_URL}yard-boxes-with-trailers`;
    const headers = new HttpHeaders();
    return this._http.get<ResponseApi>(url, { headers }).pipe(
      take(1),
      map((response) => {
        if (!response.results) return [];
        return this._setMapBoxWithTrailersList(
          response.results as YardBoxesWithTrailerApiModel[]
        );
      })
    );
  }
}
