import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthUseCaseService } from '../../../auth/application/auth-use-case.service';
import { catchError, throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const authUseCaseService = inject(AuthUseCaseService);

  const isSessionValidationRequest = req.url.includes('session');

  return next(req).pipe(
    catchError((resp: HttpErrorResponse) => {
      if (resp.status === 401 && isSessionValidationRequest) {
        authUseCaseService.logout();
      }

      if (resp.status === 401) {
        authUseCaseService.deleteSession(true);
      }

      return throwError(() => resp);
    })
  );
};
