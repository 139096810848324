import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { LoaderService } from '../../../../domain/shared/infrastructure/services/loader.service';
import {
  MatProgressBarModule,
  ProgressBarMode,
} from '@angular/material/progress-bar';

@Component({
    selector: 'app-loader',
    imports: [CommonModule, MatProgressBarModule],
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  mode: ProgressBarMode = 'indeterminate';

  isLoading$ = this._loaderService.isLoading$;

  showLoading: boolean = false;

  constructor(
    private _loaderService: LoaderService,
    private cdr: ChangeDetectorRef
  ) {
    this.isLoading$.subscribe({
      next: (isLoading) => {
        this.showLoading = isLoading;
        this.cdr.detectChanges();
      },
    });
  }
}
